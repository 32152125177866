import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { isDate, parseISO } from 'date-fns';

@Component({
  selector: 'qaroni-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
  ],
})
export class InputDateComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() required: boolean = false;

  date: FormControl<Date> = new FormControl<Date>({
    value: null,
    disabled: true,
  });

  ngOnInit(): void {
    if (this.required) {
      this.date.addValidators(Validators.required);
      this.date.updateValueAndValidity();
    }
  }

  onChange = (_value: Date | null): void => undefined;

  onTouched = (): void => undefined;

  get hasDate(): boolean {
    return this.date.value !== null && this.date?.value?.toString() !== '';
  }

  writeValue(date: any): void {
    if (!isDate(parseISO(date))) {
      return;
    }

    this.date.setValue(date);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onClearDate(event: MouseEvent): void {
    event.stopPropagation();
    this.date.setValue(null);
    this.onTouched();
    this.onChange(this.date.value);
  }
}
