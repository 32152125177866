// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from './.env';

export const environment = {
  production: false,
};

export const appEnvironment = {
  appCode: '9ac6452aba146121548e809069600c4fb5b53fdb0fd47142c71237ed98eee094',
  currentVersion: `${env.version}-dev`,
};

export const appEnvironmentWorkstations = {
  appCode: '599ecb6e76476b9925b445b8c08da103e50bcead3cc6762cbae500a278eacc12',
};

export const apiQWorkardsEnvironment = {
  baseUrl: 'https://api.qaroni.net/workards/v1',
  baseWorkstationUrl: 'https://api.qaroni.net/workards',
};

export const GoogleReCaptchaEnvironment = {
  sitekey: '6Le9Y4ghAAAAALIrwvBGK0w4bewIacUo9hrVlqOk',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
