import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum CompanyModulesEnum {
  ABSENCES = 'ABSENCES',
  CHECKS = 'CHECKS',
  COMPANY = 'COMPANY',
  EMPLOYEES = 'EMPLOYEES',
  EVALUATION = 'EVALUATION',
  FILE_MANAGER = 'FILE_MANAGER',
  HOME = 'HOME',
  IMPUTATIONS = 'IMPUTATIONS',
  PAYMENTS = 'PAYMENTS',
  PROFILE = 'PROFILE',
  PROJECTS = 'PROJECTS',
  SHIFTS = 'SHIFTS',
  TASK = 'TASK',
  TICKETS = 'TICKETS',
  USER_PROJECTS = 'USER_PROJECTS',
  USERS = 'USERS',
  WORK_CALENDAR = 'WORK_CALENDAR',
}

export const CompanyModulesArray = [
  CompanyModulesEnum.ABSENCES,
  CompanyModulesEnum.CHECKS,
  CompanyModulesEnum.EVALUATION,
  CompanyModulesEnum.FILE_MANAGER,
  CompanyModulesEnum.PROJECTS,
  CompanyModulesEnum.SHIFTS,
  CompanyModulesEnum.WORK_CALENDAR,
];

export const CompanyModulesAlwaysEnabled = [
  CompanyModulesEnum.COMPANY,
  CompanyModulesEnum.EMPLOYEES,
  CompanyModulesEnum.HOME,
  CompanyModulesEnum.PROFILE,
  CompanyModulesEnum.USERS,
];

export const CompanyModulesInfo: QaroniEnumInformation<CompanyModulesEnum> = {
  [CompanyModulesEnum.HOME]: {
    name: $localize`Inicio`,
  },
  [CompanyModulesEnum.EMPLOYEES]: {
    name: $localize`Empleados`,
  },
  [CompanyModulesEnum.PROFILE]: {
    name: $localize`Perfil`,
  },
  [CompanyModulesEnum.USERS]: {
    name: $localize`Usuarios`,
  },
  [CompanyModulesEnum.COMPANY]: {
    name: $localize`Empresa`,
  },
  [CompanyModulesEnum.CHECKS]: {
    name: $localize`Fichajes`,
  },
  [CompanyModulesEnum.PROJECTS]: {
    name: $localize`Gestión de Proyectos`,
  },
  [CompanyModulesEnum.USER_PROJECTS]: {
    name: $localize`Proyectos`,
  },
  [CompanyModulesEnum.TASK]: {
    name: $localize`Tareas`,
  },
  [CompanyModulesEnum.IMPUTATIONS]: {
    name: $localize`Imputaciones`,
  },
  [CompanyModulesEnum.ABSENCES]: {
    name: $localize`Ausencias`,
  },
  [CompanyModulesEnum.FILE_MANAGER]: {
    name: $localize`Gestión de archivos`,
  },
  [CompanyModulesEnum.SHIFTS]: {
    name: $localize`Turnos`,
  },
  [CompanyModulesEnum.WORK_CALENDAR]: {
    name: $localize`Calendario laboral`,
  },
  [CompanyModulesEnum.EVALUATION]: {
    name: $localize`Evaluaciones`,
  },
  [CompanyModulesEnum.TICKETS]: {
    name: $localize`Soporte técnico`,
  },
  [CompanyModulesEnum.PAYMENTS]: {
    name: $localize`Planes y facturación`,
  },
};
