import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum CompanyStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export type CompanyStatusType =
  | CompanyStatusEnum.ACTIVE
  | CompanyStatusEnum.CANCELLED
  | CompanyStatusEnum.CREATED
  | CompanyStatusEnum.INACTIVE
  | CompanyStatusEnum.PENDING;

export const CompanyStatusArray = [
  CompanyStatusEnum.ACTIVE,
  CompanyStatusEnum.CANCELLED,
  CompanyStatusEnum.CREATED,
  CompanyStatusEnum.INACTIVE,
  CompanyStatusEnum.PENDING,
];

export const CompanyStatusInfo: QaroniEnumInformation<CompanyStatusEnum> = {
  [CompanyStatusEnum.ACTIVE]: {
    name: $localize`Active`,
  },
  [CompanyStatusEnum.CANCELLED]: {
    name: $localize`Cancelled`,
  },
  [CompanyStatusEnum.CREATED]: {
    name: $localize`Created`,
  },
  [CompanyStatusEnum.INACTIVE]: {
    name: $localize`Inactive`,
  },
  [CompanyStatusEnum.PENDING]: {
    name: $localize`Pending`,
  },
};
