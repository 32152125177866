<button
  [matMenuTriggerFor]="menu"
  mat-icon-button
  i18n-matTooltip
  matTooltip="Opciones de la cuenta"
  class="flex items-center p-1">
  <img
    class="mx-auto block h-full w-full"
    [src]="avatar?.url"
    [alt]="avatar?.alt" />
</button>

<mat-menu #menu="matMenu" class="mt-1">
  <!-- TODO: Echarle un ojo a esto -->
  <ng-container *ngIf="user">
    <div class="text-user-name px-3">{{ user?.fullName }}</div>
    <div class="text-user-email px-3">{{ user?.email }}</div>
    <mat-divider class="mx-2 my-1"></mat-divider>
  </ng-container>

  <ng-container *ngIf="companies?.length">
    <qaroni-menu-company
      [companies]="companies"
      [user]="user"></qaroni-menu-company>
    <mat-divider class="mx-2 my-1"></mat-divider>
  </ng-container>

  <a
    mat-menu-item
    class="qaroni-pn-500-15"
    [routerLink]="goToInvitations()"
    routerLinkActive="qaroni-text-primary">
    <mat-icon class="icon-menu">card_giftcard</mat-icon>
    <ng-container i18n>Invitaciones</ng-container>
  </a>
  <a
    mat-menu-item
    class="qaroni-pn-500-15"
    [routerLink]="goToUserProfile()"
    routerLinkActive="qaroni-text-primary">
    <mat-icon class="icon-menu">perm_identity</mat-icon>
    <ng-container i18n>Perfil de usuario</ng-container>
  </a>
  <a
    mat-menu-item
    class="qaroni-pn-500-15"
    [routerLink]="goToAddCompany()"
    routerLinkActive="qaroni-text-primary">
    <mat-icon class="icon-menu">add_business</mat-icon>
    <ng-container i18n>Crear empresa</ng-container>
  </a>
  <a
    mat-menu-item
    class="qaroni-pn-500-15"
    [routerLink]="goToLogout()"
    routerLinkActive="qaroni-text-primary">
    <mat-icon class="icon-menu">exit_to_app</mat-icon>
    <ng-container i18n>Cerrar sesión</ng-container>
  </a>
</mat-menu>
