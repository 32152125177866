export const ImagesConfig = {
  default: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/login_illustration.svg`,
    alt: `Ilutración por defecto`,
  },
  workards: {
    url_white: `https://s3.eu-central-1.amazonaws.com/workards.com/images/logos/workards-logo-white.svg`,
    url_black: `https://s3.eu-central-1.amazonaws.com/workards.com/images/logos/workards-logo-black.svg`,
    alt: `Workards by Qaroni`,
  },
  signup: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/sign_up_illustration.svg`,
    alt: `Ilustración crear cuenta`,
  },
  forgot: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/forgot_illustration.svg`,
    alt: `Ilustración olvidé contraseña`,
  },
  login: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/login_illustration.svg`,
    alt: `Ilustración login`,
  },
  company: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/dafault-company-image.png`,
    alt: `Default Company Logo`,
  },
  createCompany: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/create_company.svg`,
    alt: `Create company`,
  },
  invitationNotification: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/invitation_notification.svg`,
    alt: `Invitation notification`,
  },
  spinner: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/logos/workards-logo-black.svg`,
    alt: `Spinner logo`,
  },
  maleAvatar: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/male_avatar_illustration.svg`,
    alt: `Male avatar`,
  },
  femaleAvatar: {
    url: `https://s3.eu-central-1.amazonaws.com/workards.com/images/female_avatar_illustration.svg`,
    alt: `Female avatar`,
  },
  version: {
    url: `https://workards.s3.eu-central-1.amazonaws.com/images/version_control_illustration.svg`,
    alt: `Version illustration`,
  },
  companyProfile: {
    url: 'assets/img/profile_icon_8.svg',
    alt: 'Company profile',
  },
  timeManagement: {
    url: 'assets/img/default-time-management.svg',
    alt: 'Time management',
  },
  shiftsIcon: {
    url: 'assets/img/shift_icon.svg',
    alt: 'Shifts icon',
  },
  evaluationsIcon: {
    url: 'assets/img/evaluations_icon.svg',
    alt: 'Evaluations icon',
  },
};
