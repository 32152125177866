import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { Params, Router } from '@angular/router';

@Component({
  selector: 'qaroni-come-back-button',
  templateUrl: './come-back-button.component.html',
  styleUrl: './come-back-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComeBackButtonComponent {
  @Input() routerLinkUrl: string[] = [];
  @Input() text = 'Volver';
  @Input() queryParams: Params = {};

  private router = inject(Router);
  private location = inject(Location);

  get hasRouterLinkUrl(): boolean {
    return !!this.routerLinkUrl.length;
  }

  public goToBack() {
    if (this.hasRouterLinkUrl) {
      this.router.navigate(this.routerLinkUrl, {
        queryParams: this.queryParams,
      });
    } else {
      this.location.back();
    }
  }
}
