import { Pipe, PipeTransform } from '@angular/core';
import {
  CompanyModulesEnum,
  CompanyModulesInfo,
} from '@qaroni-app/company/types/company-modules.enum';

@Pipe({
  name: 'companyModulesName',
})
export class CompanyModulesNamePipe implements PipeTransform {
  transform(value: CompanyModulesEnum): string {
    if (
      value &&
      Object.prototype.hasOwnProperty.call(CompanyModulesInfo, value)
    ) {
      return CompanyModulesInfo[value].name;
    }

    return value;
  }
}
