<mat-form-field appearance="outline" subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControl]="date"
    [matDatepicker]="datePicker"
    [min]="min"
    [max]="max"
    (focus)="datePicker.open()"
    (click)="datePicker.open()"
    (keypress)="datePicker.open()"
    (dateChange)="onChange(date?.value)"
    (input)="onTouched()"
    (blur)="onTouched()"
    autocomplete="off"
    #dateInput />
  <mat-datepicker-toggle matPrefix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker [disabled]="false"></mat-datepicker>
  <button
    mat-icon-button
    matIconSuffix
    type="button"
    i18n-aria-label
    aria-label="Limpiar"
    *ngIf="hasDate"
    (click)="onClearDate($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<div class="px-4 text-xs">
  <ng-content></ng-content>
</div>
