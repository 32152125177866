import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum PriceConstantsEnum {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

export type PriceConstantsType =
  | PriceConstantsEnum.YEAR
  | PriceConstantsEnum.MONTH
  | PriceConstantsEnum.WEEK
  | PriceConstantsEnum.DAY;

export const PriceConstantsArray = [
  PriceConstantsEnum.YEAR,
  PriceConstantsEnum.MONTH,
  PriceConstantsEnum.WEEK,
  PriceConstantsEnum.DAY,
];

export const PriceConstantsInfo: QaroniEnumInformation<PriceConstantsEnum> = {
  [PriceConstantsEnum.YEAR]: {
    name: $localize`Anual`,
  },
  [PriceConstantsEnum.MONTH]: {
    name: $localize`Mensual`,
  },
  [PriceConstantsEnum.WEEK]: {
    name: $localize`Semanal`,
  },
  [PriceConstantsEnum.DAY]: {
    name: $localize`Diario`,
  },
};
