{
  "name": "workards-ng-web",
  "version": "1.0.9",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:dev": "ng build --configuration development",
    "build:dev-ngsw": "ng build --configuration development-ngsw",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "prettier:check": "prettier --check .",
    "prettier:write": "prettier --write .",
    "i18n": "ng extract-i18n --output-path src/i18n",
    "analyzer": "webpack-bundle-analyzer dist/Workards-NG-WEB-prod/Workards-NG-WEB/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/google-maps": "^17.3.10",
    "@angular/localize": "^17.3.12",
    "@angular/material": "^17.3.10",
    "@angular/material-date-fns-adapter": "^17.3.10",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@angular/service-worker": "^17.3.12",
    "@tinymce/tinymce-angular": "^7.0.0",
    "chart.js": "^4.4.3",
    "chartjs-plugin-datalabels": "^2.2.0",
    "date-fns": "^2.30.0",
    "lottie-web": "^5.9.2",
    "ng-recaptcha": "^13.2.1",
    "rxjs": "~6.6.0",
    "tinymce": "^6.3.1",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "17.5.2",
    "@angular-eslint/eslint-plugin": "17.5.2",
    "@angular-eslint/eslint-plugin-template": "17.5.2",
    "@angular-eslint/schematics": "17.5.2",
    "@angular-eslint/template-parser": "17.5.2",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.12",
    "@types/chart.js": "^2.9.41",
    "@types/jasmine": "~3.8.0",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^8.10.0",
    "eslint-plugin-prettier": "^4.2.1",
    "jasmine-core": "~3.8.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "postcss": "^8.4.41",
    "prettier": "^3.3.3",
    "prettier-eslint": "^15.0.1",
    "prettier-plugin-tailwindcss": "^0.6.5",
    "tailwindcss": "^3.4.7",
    "typescript": "~5.4.5",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
