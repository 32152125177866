import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '@qaroni-app/auth/types/user';
import { Company } from '@qaroni-app/company/types/company';
import { CompanyStatusEnum } from '@qaroni-app/company/types/company-status.enum';
import { ImagesConfig } from '@qaroni-core/config/app/images.config';

@Component({
  selector: 'qaroni-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnChanges {
  @Input() user: User;
  @Input() companies: Company[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companies']) {
      if (this.companies?.length && this.companies.length > 0) {
        this.companies = this.companies.filter(
          company => company.status !== CompanyStatusEnum.CREATED
        );
      }
    }
  }

  get avatar() {
    return ImagesConfig.maleAvatar;
  }

  public goToInvitations(): string[] {
    return [`/invitations`];
  }

  public goToUserProfile(): string[] {
    return [`/profile`];
  }

  public goToAddCompany(): string[] {
    return [`/companies/add`];
  }

  public goToLogout(): string[] {
    return [`/auth/logout`];
  }
}
