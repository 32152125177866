import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameTruncate',
})
export class FileNameTruncatePipe implements PipeTransform {
  transform(fileName: string, maxLength: number = 10): unknown {
    if (!fileName || maxLength <= 0) return fileName;

    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) return fileName;

    const namePart = fileName.substring(0, lastDotIndex);
    const extension = fileName.substring(lastDotIndex);

    if (namePart.length <= maxLength) {
      return fileName;
    }

    const firstPart = namePart.substring(0, maxLength - 1);
    const lastChar = namePart.charAt(namePart.length - 1);

    return `${firstPart}...${lastChar}${extension}`;
  }
}
